import getConfig from 'next/config';

export enum FormNameType {
    CONTACT_FORM = 'contactForm',
    CATALOUGE_FORM = 'catalogueOrder',
    WEBBINAR_FORM = 'watchWebinar',
    NEWSLETTER_FORM = 'newsletterSignup',
    KCS_FORM = 'generalKCSform',
    TENDER_TEXT_FORM = 'tenderform',
}

export interface ContactFormResponseDto {
    Status?: 'OK' | 'ERROR';
    Id?: string;
}

class FormSubmitError extends Error {
    constructor(msg: string | undefined) {
        super(msg);
        this.name = 'FormSubmitError';
    }
}
// Need to set header manually when running in dev and nginx can't set proxyHeader
const getDevHeaders = () => {
    const headers: Record<string, string> = {};
    if (process.env.NODE_ENV !== 'development') return headers;
    const publicRuntimeConfig = getConfig();
    headers['Ocp-Apim-Subscription-Key'] = `${publicRuntimeConfig.APIM_FORM_SUBSCRIPTION_KEY}`;
    return headers;
};

export async function postForm<T>(formType: FormNameType, data: T) {
    const rawResponse = await fetch(`/api/form/${formType}`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            ...getDevHeaders(),
        },
        body: JSON.stringify(data),
    });
    const content: ContactFormResponseDto = await rawResponse.json();
    if (rawResponse.status !== 200) {
        throw new FormSubmitError(
            `Error occured while posting ${formType} form: Status was ${rawResponse.status} - ${rawResponse.statusText}`,
        );
    }
    if (content?.Status != 'OK') {
        throw new FormSubmitError(
            `Error occured while posting ${formType} form: Server did not return successful response`,
        );
    }

    return content;
}
