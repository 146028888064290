import { FormEvent, useCallback, useRef } from 'react';
import { useTracking } from '../../../shared/components/GoogleTagManager/hooks/useTracking';
import { usePage } from '~/templates/pages';
import { countryCodeKey, countryDictionary } from '~/lib/twoLetterIsoCodeCountryList';

export const useTrackFormBegin = () => {
    const { trackFormBegin } = useTracking();
    const { market } = usePage();
    const sentEventRef = useRef(false);

    let onChangeRef: NodeJS.Timeout | undefined;

    const handleTrackFormBegin = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            clearTimeout(onChangeRef);

            const target = e.target as HTMLInputElement;

            onChangeRef = setTimeout(() => {
                if (!sentEventRef.current) {
                    trackFormBegin({
                        form_name: target.name,
                        country:
                            (market && countryDictionary[market as countryCodeKey]) ||
                            target.name === 'Address1_County'
                                ? target.name
                                : market,
                    });
                    sentEventRef.current = true;
                }
            }, 2000);
        },
        [onChangeRef],
    );

    const handleTrackFormBeginOnBlur = useCallback(
        (e: FormEvent<HTMLFormElement>) => {
            clearTimeout(onChangeRef);

            const target = e.target as HTMLInputElement;

            if (!sentEventRef.current) {
                trackFormBegin({
                    form_name: target.name,
                    country:
                        (market && countryDictionary[market as countryCodeKey]) ||
                        target.name === 'Address1_County'
                            ? target.name
                            : market,
                });
                sentEventRef.current = true;
            }
        },
        [onChangeRef],
    );

    return {
        handleTrackFormBegin,
        handleTrackFormBeginOnBlur,
    };
};
